export default function SecondaryButton({
    type = "button",
    className = "",
    disabled = false,
    background = "light",
    children,
    ...props
}) {
    return (
        <button
            {...props}
            type={type}
            className={
                `inline-flex items-center px-4 py-2 border justify-center group
                rounded-3xl font-semibold text-md tracking-widest shadow-xl focus:outline-none focus:ring-2
                transition ease-in-out duration-150 disabled:cursor-not-allowed
                ${disabled ? "opacity-50" : ""}
                ${
                    !disabled && background == "light"
                        ? "hover:border-red-300 text-astronaut-blue bg-black-white hover:bg-outragous-orange hover:text-white focus:ring-outragous-orange"
                        : ""
                }
                ${
                    !disabled && background == "dark"
                        ? "border-gray-300 text-alabaster hover:bg-gray-50 hover:text-black focus:ring-outragous-orange"
                        : ""
                } ` + className
            }
            disabled={disabled}
        >
            {children}
        </button>
    );
}
